import React, { Component } from 'react'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
// import isEqual from 'lodash/isEqual';
import find from 'lodash/find'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'
import StartOver from '@e2grnd/ui-core/dist/containers/StartOver'
import withConfig from '@e2grnd/ui-core/dist/contexts/withConfig'

import { getRiskMatrixData } from '../lib/getResultCases.js'
import { stringToBlob } from '../lib/stringToBlob.ts'
import StorageDownloadButton from '../propertyPages/StorageDownloadButton.js'

class Step4 extends Component {
  static propTypes = {
    results: PropTypes.object,
    error: PropTypes.shape({
      valueString: PropTypes.string,
      valuesInteger: PropTypes.number,
      valuesFloat: PropTypes.number,
      name: PropTypes.string,
      valueType: PropTypes.number,
    }),
    config: PropTypes.object,
    summaryData: PropTypes.object,
    summaryDataR: PropTypes.object,
    thicknessPlotsReportFlag: PropTypes.bool,
    metalLossPlotsReportFlag: PropTypes.bool,
    residualPlotsReportFlag: PropTypes.bool,
    pofPlotsReportFlag: PropTypes.bool,
    nextTaDate: PropTypes.string,
    turnaroundFrequency: PropTypes.number,
    dispatch: PropTypes.func,
    circuit: PropTypes.string,
    problemType: PropTypes.string,
    htmlOutFileDummy: PropTypes.string,
    riskMatrixJson: PropTypes.string,
    rmData: PropTypes.object,
  }

  componentDidUpdate() {
    const { results, residualPlotsReportFlag, pofPlotsReportFlag, thicknessPlotsReportFlag, metalLossPlotsReportFlag, nextTaDate, turnaroundFrequency, dispatch, problemType, riskMatrixJson } = this.props
  }

  render() {
    const {
      results,
      error,
      config,
      summaryData,
      summaryDataR,
      circuit,
      problemType,
      htmlOutFileDummy,
      riskMatrixJson,
      rmData,
    } = this.props

    if(typeof results.summaryDataRowHeaders !== 'undefined') {
      const tableData = find(config.dataTables, ['name', 'summaryDataR'])
      tableData.keywords[0].rowHeaders = true
      tableData.options.rowHeaders = results.summaryDataRowHeaders
    }

    if(problemType !== 'R') {
      let modelCriteriaString = 'There are no WAIC and LOO deviance estimates available for this analysis.\n A gr_psrf = -1 indicates parameter converged.'

      if(typeof results.waicData !== 'undefined') {
        const waicData = results.waicData
        const looData = results.looData
        // eslint-disable-next-line no-unused-vars
        modelCriteriaString = `The WAIC and LOO  estimates are ${waicData[0].toFixed(4)} and ${looData[0].toFixed(4)}, SEs are ${waicData[1].toFixed(4)} and ${looData[1].toFixed(4)}, and p values are ${waicData[2].toFixed(4)} and ${looData[2].toFixed(4)} respectively.`
      }
    }
    let rmGraphData

    if(typeof results.jsonOutputData !== 'undefined') {
      if(problemType === 'R') {
        rmGraphData = getRiskMatrixData(riskMatrixJson)
      }
    }

    return (
      <div className="Step4">
        {results.summaryDataRowHeaders
          ? (
            <div>
              {problemType === 'R'
                ? (
                  <div className="fl w-100 pa3">
                    <Stack direction="row" spacing={2}>
                      <StorageDownloadButton circuit={circuit} htmlReportFileName={results.htmlReportFileName[0]}></StorageDownloadButton>
                      <Button
                        variant="outlined"
                        color="secondary"
                        onClick={() => { stringToBlob(results.jsonOutputData, circuit + '_CR.json') }}
                      >
                        {circuit} Corrosion Rate JSON Results
                      </Button>
                    </Stack>

                    <div className = "f1 w-100" style={{ border: '2px solid black', padding: '10px' }}>
                      <DataGraph plotClass="data-graph-85" data={rmGraphData.traces} layout={rmGraphData.layout} />
                    </div>
                    <div>
                      <DataTableDisplay id="rmData" table={rmData} colHeaders={{ USCustomary: ['Year', 'Circ', 'Iso/CML', 'Cons.', 'POF', 'Risk', 'Risk Target'], Metric: ['Year', 'Circ', 'Iso/CML', 'Cons.', 'POF', 'Risk', 'Risk Target'] }} containerHeight="300px" />
                    </div>

                  </div>
                )
                : ''}

              {problemType !== 'R'
                ? (
                  <div className="fl w-100">
                    <p>{modelCriteriaString}</p>
                    <DataTableDisplay id="summaryData" table={summaryData} colHeaders={{ USCustomary: ['Mean', 'SD', 'HDI 3%', 'HDI 97%', 'MCSE Mean', 'MCSE SD', 'ESS Bulk', 'ESS Tail', 'R Hat'], Metric: ['Mean', 'SD', 'HDI 3%', 'HDI 97%', 'MCSE Mean', 'MCSE SD', 'ESS Bulk', 'ESS Tail', 'R Hat'] }} containerHeight="300px" />
                  </div>
                )
                : ''}
            </div>
          )
          : ''}
        <br />
        <StartOver />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  summaryData: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.summaryData : undefined,
  summaryDataR: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.summaryDataR : undefined,
  results: state.workflow.response ? state.workflow.response.results : undefined,
  error: state.workflow.response ? state.workflow.response.error : undefined,
  thicknessPlotsReportFlag: state.workflow.fields.thicknessPlotsReportFlag,
  metalLossPlotsReportFlag: state.workflow.fields.metalLossPlotsReportFlag,
  residualPlotsReportFlag: state.workflow.fields.residualPlotsReportFlag,
  pofPlotsReportFlag: state.workflow.fields.pofPlotsReportFlag,
  nextTaDate: state.workflow.fields.nextTaDate,
  problemType: state.workflow.fields.problemType,
  turnaroundFrequency: state.workflow.fields.turnaroundFrequency,
  circuit: state.workflow.fields.circuit,
  htmlOutFileDummy: state.workflow.fields.htmlOutFileDummy,
  riskMatrixJson: state.workflow.response ? state.workflow.response.results.riskMatrixJson : undefined,
  rmData: state.workflow.response.results.dataTables ? state.workflow.response.results.dataTables.rmData : undefined,
})

export default connect(mapStateToProps, null)(
  withConfig(Step4)
)
