import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

import { getPlotlyTracesLayout } from '../lib/getResultCases.js'

const AcfPriorPosteriors = ({ results, acfPlotJson, priorPosteriorSummaryTable, circuit }) => {
  const acfPlotData = getPlotlyTracesLayout(acfPlotJson)

  return (
    <div className="cf w-100">
      {results.reinspectionPlotJson
        ? (
          <div className="fl w-100">
            <div>
              <DataGraph plotClass="data-graph-85" data={acfPlotData.traces} layout={acfPlotData.layout} />
            </div>
            <div>
              <span><b>{circuit} CML POF Summary at Analysis Date, T/A 1 and T/A 2</b></span>
              <DataTableDisplay id="priorPosteriorSummaryTable" table={priorPosteriorSummaryTable} containerHeight="200px" />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

AcfPriorPosteriors.propTypes = {
  results: PropTypes.object,
  acfPlotJson: PropTypes.string,
  priorPosteriorSummaryTable: PropTypes.object,
  circuit: PropTypes.string,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  acfPlotJson: state.workflow.response ? state.workflow.response.results.acfPlotJson : undefined,
  priorPosteriorSummaryTable: state.workflow.response ? state.workflow.response.results.dataTables.priorPosteriorSummaryTable : undefined,
  circuit: state.workflow.fields.circuit,
})

export default connect(mapStateToProps, null)(AcfPriorPosteriors)
