import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import DataGraph from '@e2grnd/ui-core/dist/containers/DataGraph'
import DataTableDisplay from '@e2grnd/ui-core/dist/containers/DataTableDisplay'

import { getPlotlyTracesLayout } from '../lib/getResultCases.js'

const CmlPof = ({ results, circuit, cmlPofPlotJson, cpColumnHeaders, cmlPofSummaryTable }) => {
  const cmlPofPlotData = getPlotlyTracesLayout(cmlPofPlotJson)

  return (
    <div className="cf w-100">
      {results.cmlPofPlotJson
        ? (
          <div className="fl w-100">
            <div>
              <DataGraph plotClass="data-graph-85" data={cmlPofPlotData.traces} layout={cmlPofPlotData.layout} />
            </div>
            <br />
            <div>
              <span><b>{circuit} CML POF Summary at Analysis Date, T/A 1 and T/A 2</b></span>
              <DataTableDisplay id="cmlPofSummaryTable" table={cmlPofSummaryTable} colHeaders={{ USCustomary: cpColumnHeaders, Metric: cpColumnHeaders }}containerHeight="200px" />
            </div>
          </div>
        )
        : <p>No Graphs to Display</p>}
    </div>
  )
}

CmlPof.propTypes = {
  results: PropTypes.object,
  circuit: PropTypes.string,
  cmlPofPlotJson: PropTypes.string,
  cpColumnHeaders: PropTypes.array,
  cmlPofSummaryTable: PropTypes.object,
}

const mapStateToProps = state => ({
  results: state.workflow.response ? state.workflow.response.results : undefined,
  circuit: state.workflow.fields.circuit,
  cmlPofPlotJson: state.workflow.response ? state.workflow.response.results.cmlPofPlotJson : undefined,
  cpColumnHeaders: state.workflow.response ? state.workflow.response.results.cpColumnHeaders : undefined,
  cmlPofSummaryTable: state.workflow.response ? state.workflow.response.results.dataTables.cmlPofSummaryTable : undefined,
})

export default connect(mapStateToProps, null)(CmlPof)
