import React, { useState } from 'react'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'

import { useAuthContext } from '@e2grnd/ui-core'

const StorageDownloadButton = ({ circuit, htmlReportFileName }) => {
  const { token } = useAuthContext()
  const [error, setError] = useState()

  const handleDownload = async (fn) => {
    const filename = Array.isArray(fn) ? fn[0] : fn

    if(filename) {
      try {
        const url = await fetch(`/storage/volatile-file?name=${filename}`, { headers: { Authorization: `Bearer ${token}` } })
        if(url.ok) {
          const text = await url.text()
          const res = await fetch(text)
          if(res.ok) {
            setError(undefined)
            const blob = await res.blob()
            const u = window.URL.createObjectURL(blob)
            const link = document.createElement('a')
            link.href = u
            link.setAttribute('download', filename.split('/').pop())
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          } else {
            setError('Error occurred when retrieving output file')
          }
        }
      } catch(err) {
        throw new Error(err)
      }
    }
  }

  return (
    <div className="cf w-100">
      <Button
        variant="outlined"
        color="secondary"
        onClick={() => { handleDownload(htmlReportFileName) }}
      >
        {circuit} Report Results from {htmlReportFileName}
      </Button>
    </div>
  )
}

StorageDownloadButton.propTypes = {
  circuit: PropTypes.string,
  htmlReportFileName: PropTypes.string,
}

export default StorageDownloadButton
